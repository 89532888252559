import { Layout } from '@components';
import { usePrefersReducedMotion } from '@hooks';
import { navDelay } from '@utils';
import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';

const StyledMainContainer = styled.main`
  counter-reset: section;
  max-width: 1000px;
  margin: 0 auto;
  padding: 100px 0;

  @media (max-width: 768px) {
    padding: 80px 0;
  }

  h1 {
    margin: 0 0 30px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-heading), 5vw, 80px);
    font-weight: 600;
  }

  h2 {
    color: var(--lightest-slate);
    font-size: clamp(24px, 5vw, 32px);
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  h3 {
    color: var(--white);
    font-size: clamp(18px, 4vw, 24px);
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  p {
    margin: 20px 0;
    line-height: 1.7;
  }

  ul {
    padding-left: 20px;
  }

  li {
    margin-bottom: 10px;
    list-style-type: disc;
    line-height: 1.7;
  }

  .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
    margin-top: 30px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .service-card {
    background-color: var(--light-navy);
    border-radius: var(--border-radius);
    padding: 30px;
    box-shadow: 0 10px 30px -15px var(--navy-shadow);
    transition: var(--transition);

    &:hover {
      transform: translateY(-5px);
    }

    h3 {
      margin-top: 0;
      color: var(--green);
    }
  }

  .contact-section {
    margin-top: 60px;
    text-align: center;
  }

  .contact-button {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 30px;
  }
`;

const LLCPage = ({ location }) => {
  const prefersReducedMotion = usePrefersReducedMotion();

  const content = (
    <StyledMainContainer>
      <h1>AI & Machine Learning Services</h1>
      <p className="subtitle">
        Welcome to Zaarab AI Consulting LLC – Your Partner for Advanced AI Solutions.
      </p>

      <h2>About Us</h2>
      <p>
        At Zaarab AI Consulting LLC, we specialize in providing cutting-edge AI engineering,
        software development, and data annotation services for businesses looking to harness the
        power of machine learning. With a strong foundation in software engineering and AI
        technologies, we help organizations optimize workflows, build intelligent systems, and gain
        valuable insights from their data.
      </p>

      <h2>Our Services</h2>
      <div className="services-grid">
        <div className="service-card">
          <h3>AI/ML Engineering & Implementation</h3>
          <ul>
            <li>Developing end-to-end machine learning solutions.</li>
            <li>Designing and implementing AI models tailored to your business needs.</li>
            <li>Integrating AI systems into existing infrastructures.</li>
          </ul>
        </div>

        <div className="service-card">
          <h3>Technical Writing & Content Creation</h3>
          <ul>
            <li>Producing high-quality technical content focused on AI/ML workflows.</li>
            <li>
              Creating documentation, tutorials, and blog posts to explain complex AI concepts.
            </li>
          </ul>
        </div>

        <div className="service-card">
          <h3>Data Labeling & Annotation</h3>
          <ul>
            <li>
              Providing accurate, high-quality data annotation services for training machine
              learning models.
            </li>
            <li>Specializing in image, text, and audio data labeling for supervised learning.</li>
          </ul>
        </div>
      </div>

      <h2>Why Choose Us?</h2>
      <ul>
        <li>
          <strong>Expertise in AI & Machine Learning:</strong> Leveraging the latest AI technologies
          to deliver powerful, scalable solutions.
        </li>
        <li>
          <strong>Tailored Approach:</strong> Customizing our services to meet your specific project
          requirements.
        </li>
        <li>
          <strong>Reliability & Professionalism:</strong> Committed to delivering high-quality work
          with clear communication and efficient timelines.
        </li>
      </ul>

      <div className="contact-section">
        <h2>Get in Touch</h2>
        <p>
          Ready to transform your ideas into AI-driven solutions? Contact us today to discuss your
          project needs.
        </p>
        <a className="contact-button" href="mailto:marwan.zaarab@pm.me">
          Contact Us
        </a>
      </div>
    </StyledMainContainer>
  );

  return (
    <Layout location={location}>
      {prefersReducedMotion ? (
        <>{content}</>
      ) : (
        <TransitionGroup component={null}>
          <CSSTransition classNames="fadeup" timeout={navDelay}>
            <>{content}</>
          </CSSTransition>
        </TransitionGroup>
      )}
    </Layout>
  );
};

LLCPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default LLCPage;
